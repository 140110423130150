<template>
  <div class="page_box">
       <div class="month_text">06</div>
       <div class="todo_box_list">
         <div class="todo_card" v-for="(item,index) in 5" :key="index">
         <div class="day_text">{{item}}</div>
         <div class="unit_box" v-if="index==0">
           <div class="do_unit"  v-for="(unitItem,unitIndex) in 9" :key="unitIndex">
           {{unitItem}}
           完成行业标准相关内容整理内容内容
           </div>
         </div>
         <div v-else class="no-content">
         <span>
          暂无内容
         </span>
         </div>
         </div>
       </div>
       <div class="add_botton">
       <img src="@/static/imgs/add.svg" alt="">
       </div>
    </div>
</template>

<script>
// import { darkTheme } from 'naive-ui'
// import {ref} from 'vue'
export default {
  name: 'homePage',
  props: {
    msg: String
  },
  setup() {
    // let showRail=ref(true);
    // let showBackground=ref(true);
    //   return {
    //     darkTheme,showRail,showBackground
    //   }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page_box{
position:relative;
width: 100%;
height: 100%;
background-color:#000;
color:#fff;
padding:10px;
}
.month_text{
  position: absolute;
  left: 10px;
  top: 5px;
  color: #fff;
  font-size: 80px;
  flex-wrap: wrap;
}
.day_text{
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 18px;
  font-weight: bold;
}
.todo_box_list{
  position: relative;
  width: 100%;
  height: 68%;
  top: 20%;
  display: flex;
  overflow: auto;
}
.add_botton{
  position: absolute;
  width: 35px;
  height: 35px;
  background-color: #fff;
  right:10px;
  bottom: 10px;
  border-radius: 50%;
  color: #000;
  display:flex;
  align-items: center;
  justify-content: center;
}
.add_botton img{
  position: relative;
  width:50%;
  height: 50%;
  /* background: url('@/static/imgs/add.svg'); */
}
.todo_card{
  position: relative;
  width: 22%;
  height: 100%;
  flex-shrink:0;
  background-color: #fff;
  margin-right:10px;
  border-radius:8px;
  color: #000
}
.unit_box{
  position: relative;
  width: 100%;
  height: calc(100% - 50px);
  /* background-color: red; */
  margin-top: 30px;
  overflow: auto;
}
.do_unit{
 position: relative;
 width: calc(100% - 20px);
 margin: 10px;
 background-color: #fdfdfd;
 border:1px solid #00000044;
 margin-top: 0px;
 border-radius: 10px;
 font-size: 11px;
 padding: 8px; 
 text-align: left;
}
.do_unit:hover{
  background-color: #000;
  color: #fff
}
.do_unit:nth-child(2n+1){
  background-color: #000;
  color: #fff
}
.no-content{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px
}
</style>
