import { createApp } from 'vue'
import App from './App.vue'
import naive from 'naive-ui'
//引入waveUI
import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'
//引入路由
import router from './router'

console.log("naive",naive)
createApp(App).use(WaveUI).use(router).mount('#app')
