<template>
  <div class="page_box">

    </div>
</template>

<script>
// import { darkTheme } from 'naive-ui'
// import {ref} from 'vue'
export default {
  name: 'homePage',
  props: {
    msg: String
  },
  setup() {
    // let showRail=ref(true);
    // let showBackground=ref(true);
    //   return {
    //     darkTheme,showRail,showBackground
    //   }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page_box{
position:relative;
width: 100%;
height: 100%;
background-color:#000;
color:#fff;
padding:10px;
}
</style>
