<template>
  <homePage msg="Welcome to Your Vue.js App"/>
</template>

<script>
import homePage from './views/homePage.vue'

export default {
  name: 'App',
  components: {
    homePage
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  box-sizing:border-box
}

/* 滚动条样式 */
::-webkit-scrollbar-thumb {
  background: #2A455B;
  min-width: 200px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
  margin: 4px 0;
}

::-webkit-scrollbar-thumb {
  background: #2A455B;
  border-radius: 5px;
}
.scrollbar-show::-webkit-scrollbar-thumb {
  background: #2A455B;
}
</style>
