// src/router/index.js
import { createRouter, createWebHashHistory } from 'vue-router';
// import Home from '../views/homePage.vue';
import About from '../views/About.vue';
import todolist from '../views/TodoList.vue';


const routes = [
  {
    path: '/',
    redirect: { name: 'todolist' }
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {//待办列表
    path: '/TodoList',
    name: 'todolist',
    component: todolist
  },
  // ...更多路由配置
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});
export default router;